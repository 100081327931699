@include only-for-mobile-landscape() {
  .login__wrapper .left1 .carousel {
    .carousel-inner .carousel-item .carousel-caption {
      min-height: 160px;

      h1 {
        font-size: $h4-font-size;
        line-height: 1.2;
      }
    }

    .carousel-indicators {
      bottom: 60px;
    }
  }
}
