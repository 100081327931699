.form-control {
  border-color: $gray-300;
  color: $gray-800;
  border-radius: 4px;

  &:hover,
  &:focus,
  &:active,
  &.active {
    border-color: $primary;
    color: $gray-800;
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: $gray-700;

    &:hover {
      border-color: $gray-300;
    }
  }

  &.error {
    border-color: $danger;
  }

  &::-webkit-input-placeholder {
    /* Edge */
    color: $gray-400;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer */
    color: $gray-400;
  }

  &::placeholder {
    color: $gray-400;
  }
}

input {
  &::-webkit-input-placeholder {
    /* Edge */
    color: $gray-400;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer */
    color: $gray-400;
  }

  &::placeholder {
    color: $gray-400;
  }
}
