.react-code-input {
  input {
    overflow: hidden;
    font-size: 18px !important;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 4px 0px !important;

    &:focus,
    &:focus-visible {
      border: 1px solid $primary !important;
      outline: 0px;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
