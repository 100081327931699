@font-face {
  font-family: 'fusetalent';
  src: url('fonts/fusetalent.eot?qdof4g');
  src: url('fonts/fusetalent.eot?qdof4g#iefix') format('embedded-opentype'),
    url('fonts/fusetalent.ttf?qdof4g') format('truetype'), url('fonts/fusetalent.woff?qdof4g') format('woff'),
    url('fonts/fusetalent.svg?qdof4g#fusetalent') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='ft-'],
[class*=' ft-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fusetalent' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ft-bold {
  font-weight: 600;
  vertical-align: text-top;
}

.ft-xm {
  font-size: $font-size-xs;
}
.ft-sm {
  font-size: $font-size-sm;
}
.ft-lg {
  font-size: $font-size-lg;
}
.ft-md {
  font-size: $font-size-base;
}
.ft-1x {
  font-size: 1.5rem;
}
.ft-2x {
  font-size: 2em;
}
.ft-3x {
  font-size: 3em;
}
.ft-4x {
  font-size: 4em;
}
.ft-5x {
  font-size: 5em;
}
.ft-fw {
  width: 1.28571429em;
  text-align: center;
}

.ft-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.ft-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.ft-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.ft-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.ft-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

.ft-base:before {
  content: '\e900';
}
.ft-location:before {
  content: '\e901';
}
.ft-refresh:before {
  content: '\e902';
}
.ft-arrow-left:before {
  content: '\e903';
}
.ft-close:before {
  content: '\e904';
}
.ft-delete:before {
  content: '\e905';
}
.ft-external-link:before {
  content: '\e906';
}
.ft-import:before {
  content: '\e907';
}
.ft-notification:before {
  content: '\e908';
}
.ft-score:before {
  content: '\e909';
}
.ft-settings:before {
  content: '\e90a';
}
.ft-three-dots:before {
  content: '\e90b';
}
.ft-upload:before {
  content: '\e90c';
}
.ft-search:before {
  content: '\e90d';
}
.ft-view:before {
  content: '\e90e';
}
.ft-stop:before {
  content: '\e90f';
}
.ft-tick:before {
  content: '\e910';
}
.ft-angle:before {
  content: '\e911';
}
.ft-file:before {
  content: '\e912';
}
.ft-folder:before {
  content: '\e913';
}
.ft-plus:before {
  content: '\e914';
}
.ft-sort:before {
  content: '\e915';
}
.ft-angle-left:before {
  content: '\e916';
}
.ft-angle-right:before {
  content: '\e917';
}
.ft-angle-top:before {
  content: '\e918';
}
.ft-sourcing:before {
  content: '\e919';
}
.ft-logout:before {
  content: '\e91a';
}
.ft-home:before {
  content: '\e91b';
}
.ft-training:before {
  content: '\e91c';
}
.ft-alert-circle:before {
  content: '\e91d';
}
.ft-level:before {
  content: '\e91e';
}
.ft-edit:before {
  content: '\e91f';
}
.ft-circle-tick:before {
  content: '\e920';
}
.ft-tests:before {
  content: '\e921';
}
.ft-star-fill:before {
  content: '\e922';
}
.ft-sort-up:before {
  content: '\e923';
}
.ft-attachment:before {
  content: '\e924';
}
.ft-globe:before {
  content: '\e925';
}
.ft-badges:before {
  content: '\e926';
}
.ft-star:before {
  content: '\e927';
}
.ft-circle-tick-fill:before {
  content: '\e929';
}
.ft-clock:before {
  content: '\e92a';
}
.ft-calendar:before {
  content: '\e92b';
}
.ft-jobs:before {
  content: '\e92c';
}
.ft-locked:before {
  content: '\e92d';
}
.ft-sort-down:before {
  content: '\e92e';
}
.ft-image:before {
  content: '\e92f';
}
.ft-filter:before {
  content: '\e930';
}
.ft-circle-cross:before {
  content: '\e931';
}
.ft-stamp:before {
  content: '\e932';
}
.ft-sent-request:before {
  content: '\e933';
}
.ft-question:before {
  content: '\e934';
}
.ft-important:before {
  content: '\e935';
}
.ft-add-resources:before {
  content: '\e936';
}
.ft-alert-triangle:before {
  content: '\e937';
}
.ft-angle-down:before {
  content: '\e938';
}
.ft-availability-circle:before {
  content: '\e939';
}
.ft-copy:before {
  content: '\e93c';
}
.ft-download:before {
  content: '\e93d';
}
.ft-github:before {
  content: '\e93e';
}
.ft-linkedin:before {
  content: '\e93f';
  color: #0077b7;
}
.ft-locked-circle:before {
  content: '\e940';
}
.ft-message:before {
  content: '\e941';
}
.ft-phone:before {
  content: '\e942';
}
.ft-employee-management:before {
  content: '\e943';
}
.ft-dots-menu:before {
  content: '\e944';
}
.ft-calculator:before {
  content: '\e946';
}
.ft-client-manage:before {
  content: '\e947';
}
.ft-compliance:before {
  content: '\e948';
}
.ft-employee:before {
  content: '\e949';
}
.ft-engage:before {
  content: '\e94a';
}
.ft-hide:before {
  content: '\e94b';
}
.ft-Hire:before {
  content: '\e94c';
}
.ft-manage:before {
  content: '\e94d';
}
.ft-menu:before {
  content: '\e94e';
}
.ft-neutral:before {
  content: '\e94f';
}
.ft-sad:before {
  content: '\e950';
}
.ft-smile:before {
  content: '\e951';
}
.ft-squads-manage:before {
  content: '\e952';
}
.ft-train:before {
  content: '\e953';
}
.ft-ecma:before {
  content: '\e928';
}
