.PhoneInputInput {
  border: 0;
  box-shadow: none;
  outline: 0;

  &:focus,
  &:focus-visible {
    box-shadow: none;
    border: 0;
  }
}
