@include only-for-mobile() {
  h2,
  .h2 {
    font-size: 22px;
  }

  h4,
  .h4 {
    font-size: 16px;
  }

  // login and signup
  .login__wrapper {
    height: auto;

    .email-support {
      z-index: 1;
      top: 18px;

      .text-primary {
        color: $white !important;
      }

      &.ft .text-primary {
        color: $primary !important;
      }
    }

    .left {
      background: $primary-dark-1;
      border-radius: 0 0 40px 40px;

      .content {
        top: 0;
        transform: translateY(0);
        max-width: 100%;
        margin: 0;
        padding: 15px 0;
        position: relative;

        .logo {
          height: 24px;
        }

        h1 {
          font-size: 24px;
        }

        .client__logo {
          // display: block;
          img {
            height: 20px;
          }
        }

        .power-by {
          position: absolute;
          top: 48px;
          left: 0;
          width: 200px;
          z-index: 1;

          .text-muted {
            color: $white !important;
          }
        }
      }
    }

    .left1 {
      background-color: $primary-dark-1;
      border-radius: 0 0 26px 26px;
      overflow: hidden;

      .logo-mb {
        display: block;
        margin: 12px 25px;
      }
      .carousel {
        .carousel-inner {
          .carousel-item {
            height: auto;

            img {
              display: none;
            }

            .carousel-caption {
              position: relative;
              min-height: 150px;
              background: $primary-dark-1;

              h1 {
                font-size: 22px;
                line-height: 26px;
              }
            }
          }
        }

        .carousel-indicators {
          bottom: 15px;
        }
      }
    }

    .right {
      .content {
        .logo {
          display: none;
        }

        .social__login {
          display: block;

          .btn {
            width: 100%;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .powered-by {
    position: relative;
    left: 0;
    bottom: 15px;
    color: $black;
    margin-top: 25px;
  }

  .loader {
    &.login {
      max-width: 100%;
    }
  }

  // from
  .form {
    &__widget {
      padding: 12px 0;

      .logo {
        width: 159px;
        margin-bottom: 35px;
      }

      &--item {
        display: inline-block;
        vertical-align: top;
        text-align: center;
        max-width: 65px;

        .num {
          margin: auto;
        }

        .text {
          font-size: 12px;
        }
      }

      .power-by {
        left: 15px;
        top: 55px;
        width: 200px;
      }
    }

    &--procress {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__onboarding {
      height: auto;
      margin-right: 0;

      .form-group {
        &.row {
          max-width: 100%;
          // overflow: hidden;

          .col-sm-6:first-child {
            margin-bottom: 20px;
          }
        }

        > .row {
          margin: 0;

          > div {
            padding-left: 0;
          }
        }
      }

      &.vt-2 .content {
        padding: 0 15px;
      }
    }

    &--header,
    &--body,
    &--footer {
      padding: 6px 0;
    }

    &--body {
      min-height: calc(100vh - 240px - 74px);
    }

    &--footer {
      padding-bottom: 12px;
    }

    &-group {
    }
  }

  // onboarding side process
  .form__widget1 {
    height: auto;

    .form--procress {
      margin-top: 0;
      max-height: 100%;
    }

    &--item {
      .text {
        font-size: $font-size-sm;
        margin-top: 5px;
      }

      .step-progress {
        width: 112px;
        top: 28px;
        left: 32px;
        z-index: 0;

        @include rotate(0deg);
      }

      &:after {
        height: 2px;
        width: 100px;
        top: 28px;
        left: 33px;
        display: none;
      }
    }

    .quotes {
      display: none;
    }

    .btn--logout {
      position: absolute;
      top: 15px;
      right: 15px;
      .text {
        display: none;
      }
    }
  }

  .btn-lg {
    text-transform: capitalize;
    padding: 6px 12px;
  }

  .wrapper {
    flex-direction: column;
    position: relative;
    height: auto;
    min-height: 100vh;

    .email-support {
      .text-primary {
        color: $white !important;
      }
    }
  }

  // side menu
  aside {
    height: auto;
    max-width: 100%;
    display: flex;
    padding: 6px 16px;
    height: 64px;
    overflow: hidden;
    align-items: center;
    z-index: 9;

    .logo {
      padding: 0;

      .logo {
        &--lg {
          display: none;
        }

        &--sm {
          display: block;
        }
      }
    }

    .toggle {
      display: block;

      &--icon {
        display: block;

        .ft-arrow-left {
          display: inline-block;
        }
      }
    }

    .mainmenu {
      // display: none;
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 9;
      height: 100vh;
      background: rgba($black, 0.5);
      max-width: 100%;
      left: 100%;
      padding-right: 0;

      @include transition(all, ease-in-out, 300ms);

      .mb-bg {
        padding: 12px 16px;
        margin-left: auto;
      }

      &.show {
        // display: block;
        left: 0;
      }
    }
  }

  .hiring__procress .step {
    min-width: auto;
    .text {
      font-size: $small-font-size;
    }
  }

  // top navigation
  .top--nav {
    .nav--left {
      .logo--lg {
        display: none;
      }
      .logo--sm {
        display: block;
      }
    }
    .nav--right {
      .dropdown-toggle {
        .user__info {
          display: none;
        }
      }
    }

    &.top__sm {
      display: block;

      .nav--right {
        span {
          display: inline-block;
          margin: 15px 0 10px;
          font-size: $font-size-base;
        }
      }
    }
  }

  .online__form.fm-new {
    padding-top: 0;
    height: calc(100vh - 83px);

    .online__progress {
      top: 0;
      position: absolute;
      margin-top: 0;
    }
  }

  .image-modal {
    .modal-header {
      padding: 0px;
    }

    .modal-body {
      .enlarge {
        &__image {
          position: relative;
          max-height: 65vh;
          min-height: 65vh;
          min-width: 340px;
          max-width: 100%;
        }
      }
    }
  }

  .main--wrapper {
    &.view--mb {
      .wrapper {
        height: auto;
        min-height: calc(100vh - 83px);
      }
    }
  }
}
