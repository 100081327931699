.badges {
  padding: 2px 12px;
  font-size: $font-size-sm;
  &__rounder {
    border-radius: 18px;
    color: $white;
    &-orange {
      background: $orange;
    }
    &-blue {
      background: $blue;
    }
    &-purple {
      background: $purple;
    }
    &-green {
      background: $green;
    }
    &-red {
      background: $red;
    }
    &-magenta {
      background: $pink;
    }
    &-yellow {
      background: $yellow;
    }
    &-gray {
      background-color: $gray-500;
      color: $white;
    }
  }
  &__rectangle {
    border-radius: 4px;
    &-purple {
      background: $bg-color;
      color: $primary;
    }
    &-orange {
      background: $info-light;
      color: $info;
    }
    &-yellow {
      background: $warning-light;
      color: $warning;
    }
    &-red {
      background: $danger-light;
      color: $danger;
    }
    &-green {
      background: $success-light;
      color: $success;
    }
  }
}
