.online {
  &--info {
    padding: 16px 24px;
    border-radius: 40px 40px 40px 0;
    background: $white;
  }

  &--recommendation {
    background: $white;
    border-radius: 4px;
    padding: 16px;
  }

  &-nav {
    background: $primary-dark-1;
    padding: 12px 15px;
    color: $white;
    display: flex;
    align-items: center;
  }

  &__form {
    padding: 12px 0 0;
    height: calc(100vh - 62px);
    overflow: auto;

    .online__progress {
      .progress {
        height: 1px;
        background: $gray-400;
      }
    }

    &.fm-new {
      .online__progress {
        position: fixed;
        left: 0;
        right: 0;
        top: 62px;

        .progress {
          height: 6px;
          background: $gray-400;
          border-radius: 0;
        }
      }

      > .bg-white {
        box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);
      }
    }

    .h4 {
      > :first-child {
        display: inline;
      }
    }

    &--content {
      min-height: calc(100% - 70px);

      .h4::first-letter {
        margin-left: -17px;
      }
    }
  }
}

.enlarge {
  position: relative;

  &__image {
    min-width: 60px;
    max-height: 60px;
    border-radius: 4px;
    border: 1px solid $gray-200;

    img {
      margin: 4px;
    }
  }

  &__icon {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid $primary;
    background: $primary;
    text-align: center;

    top: -10px;
    right: -10px;
    padding: 1px;

    &:hover,
    &:focus {
      background-color: $primary-dark-1;
      border-color: $primary-dark-1;
      box-shadow: none;
    }

    i {
      font-size: 16px;
      line-height: 19px;
      color: $white;
    }
  }

  &__label {
    margin-left: 10px;
    color: $primary-dark-2;
    font-weight: $font-weight-bold;
  }

  &__question {
    text-align: center;
    // height: calc(100% - 50px);
    min-height: 50vh;
    position: relative;
    margin-bottom: 5px;

    img {
      max-height: 50vh;
      max-width: 100%;
    }
  }
}

.image-modal {
  .modal-header {
    padding: 0px;
  }

  .modal-body {
    position: relative;

    .enlarge {
      &--label {
        font-size: 16px;
      }

      &__image {
        position: relative;
        max-height: 65vh;
        min-height: 65vh;
        min-width: 780px;
        max-width: 780px;
        text-align: center;
        margin-bottom: 16px;
        border-radius: 8px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin: 8px;
          max-height: 60vh;
          max-width: 100%;
        }

        .tools {
          position: absolute;
          bottom: 10px;
          right: 10px;
          z-index: 9;
        }
      }

      &__body {
        .custom-radio {
          border: none;
        }

        .custom-checkbox--block {
          border: none;
        }

        &--angle {
          i {
            font-weight: $font-weight-bold;
            font-size: 20px;
          }
        }

        .disabled {
          color: $gray-300;
          cursor: initial;
        }
      }

      &__close {
        position: absolute;
        right: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #5b5b60;
        background: #5b5b60;
        text-align: center;
        margin-right: 16px;
        z-index: 9;

        cursor: pointer;

        &:hover,
        &:focus {
          background-color: $gray-900;
          border-color: $gray-900;
          box-shadow: none;
        }

        i {
          font-size: 16px;
          line-height: 28px;
          color: $white;
        }
      }
    }
  }
}

.skip-modal {
  .modal-header {
    padding: 0;
  }

  .title {
    font-size: $font-size-lg;
  }
}
