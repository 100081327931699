.calendy {
  &--info {
    max-width: 302px;
    margin: 12px auto;
    text-align: left;
  }
}

.NqanGvcWiCU6BArN5gOD {
  display: none !important;
}
