.login {
  &__wrapper {
    height: 100vh;

    .email-support {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .left {
      height: 100%;
      background: $primary;
      background-image: url('/images/login-image.jpg');
      background-image: linear-gradient(to bottom, transparent, #000000e3), url('/images/login-image.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      color: $white;

      .content {
        max-width: 590px;
        padding: 25px 15px;
        margin: 0 0 0 10%;

        @include vertical-align(90%);

        .client__logo {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    .left1 {
      padding: 0;
      height: 100%;

      .logo-mb {
        display: none;
        margin: 12px 25px;
      }

      .carousel {
        height: 100%;

        .carousel-inner {
          height: 100%;

          .carousel-item {
            height: 100%;
            overflow: hidden;

            img {
              width: 100%;
              // width: fit-content;
              vertical-align: bottom;
            }

            .carousel-caption {
              right: 0;
              left: 0;
              bottom: 0;
              background-image: linear-gradient(to bottom, transparent, #000000);
              min-height: 280px;
              text-align: left;
              padding: 12px 25px;

              h1 {
                font-size: 36px;
                line-height: 48px;
                font-weight: 700;
                max-width: 700px;
              }
            }
          }
        }

        .carousel-indicators {
          left: 25px;
          right: 0;
          bottom: 90px;
          margin: 0;
          justify-content: start;

          button {
            width: 8px;
            height: 8px;
            box-shadow: none;
            border-radius: 100px;
            border: 0;
            margin-right: 2px;

            @include transition(all, 300ms, ease-in-out);

            &.active {
              width: 20px;
            }
          }
        }

        .carousel-control-prev,
        .carousel-control-next {
          top: auto;
          bottom: 15px;
          width: 24px;
          height: 24px;
          opacity: 0.8;
          right: 15px;
          &:hover {
            opacity: 1;
          }
        }

        .carousel-control-prev {
          right: 40px;
          left: auto;
        }
      }
    }

    .right {
      max-height: 100%;

      @extend .scrollbar;

      .content {
        max-width: 412px;
        width: 100%;
        margin: auto;
        padding: 15px 0;

        .social__login {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          column-gap: 10px;

          .btn {
            min-width: 130px;
            text-transform: none;
            background: $white;
            flex: 1 auto;

            img {
              margin-right: 5px;
              vertical-align: bottom;
            }
          }
        }

        .divider {
          margin: 15px 0;
          position: relative;
          text-align: center;
          z-index: 0;

          span {
            background: white;
            padding: 0 5px;
          }

          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            height: 1px;
            background: $gray-400;
            z-index: -1;
          }
        }
      }
    }
  }
}

.forgot__password {
  max-width: 400px;
  width: 100%;
  margin: auto;
  padding: 12px 15px;
}

.verify__account {
  max-width: 491px;
  width: 100%;
  margin: auto;
  padding: 12px 15px;
}

.powered-by {
  position: absolute;
  z-index: 10;
  left: 25px;
  bottom: 15px;
  color: $white;
  font-size: $font-size-xs;
}
