@include only-for-tablet-landscape {
  .login__wrapper .left1 .carousel {
    .carousel-inner .carousel-item .carousel-caption {
      // min-height: 160px;
    }

    .carousel-indicators {
      bottom: 60px;
    }
  }
}

@include only-for-tablet() {
  .app-slogan {
    font-size: 34px;
  }
  .form__widget {
    padding: 12px 15px;
    color: #ffffff;
  }

  h1 {
    font-size: 35px;
  }

  .login__wrapper {
    .left {
      .content {
        top: 100%;
        transform: translateY(-100%);
        max-width: 100%;
        margin: 0;

        h1 {
          margin-top: 32px !important;
        }

        .client__logo {
          // display: block;
          img {
            height: 25px;
          }
        }
      }
    }

    .left1 .carousel {
      .carousel-inner .carousel-item .carousel-caption {
        min-height: 400px;
      }

      .carousel-indicators {
        bottom: 80px;
      }
    }

    .right {
      .content {
        padding-top: 25px;

        .social__login {
          display: block;

          .btn {
            width: 100%;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .form {
    &__onboarding {
      // padding: 0 0;
    }
  }

  .wrapper {
    overflow: auto;
  }
}
