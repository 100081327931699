.client__logo {
  display: block;

  img {
    margin-right: 15px;
    margin-bottom: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.success-check {
  display: inline-block;
  padding: 10px 12px;
  background: $green;
  color: $white;
  border-radius: 50%;
  margin: 12px 0;
}

.application {
  background: $white;
  border-radius: 8px;
  border: 1px solid $primary;
  padding: 30px 32px;
  &--review {
    max-width: 506px;
  }
  &--failed {
    max-width: 740px;
  }
}

.interview--format {
  display: block;
  margin: 15px auto;
  border-radius: 4px;
  background: $primary;
  color: $white;
  max-width: 280px;
  padding: 16px 26px;
  text-align: left;

  ul {
    padding-left: 15px;
    li {
      // display: flex;
      margin: 4px 0;
      span {
        float: right;
      }
    }
  }
}

.skills {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    grid-gap: 1.25rem;
    max-width: 700px;
  }

  &--item {
    border: 1px solid $gray-400;
    background: $white;
    border-radius: 8px;
    display: inline-block;
    cursor: pointer;
    height: 100%;
    text-align: center;
    padding: 0.5rem 0.5rem;
    position: relative;
    overflow: hidden;

    .link {
      color: $gray-500;
      padding: 16px 12px;
      border-top: 1px solid $gray-300;
      margin-top: 16px;
    }

    h5 {
      height: calc(100% - 158px);
    }

    &:hover {
      border-color: $primary;
      .link {
        color: $primary;
      }
    }

    .disabled {
      opacity: 0.5;
    }

    .passed-card {
      position: absolute;
      top: -2px;
      right: 5px;
      color: $yellow;
    }

    &.completed {
      border: 1px solid $primary;

      .ft-badges {
        position: absolute;
        top: 0;
        right: 12px;
      }

      .link {
        color: $green;
      }
    }
  }
}
