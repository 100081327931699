.loader {
  display: block;
  position: absolute;
  padding: 12px 15px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgba($primary, 0.42);
  z-index: 9;

  img {
    @include vertical-align(50%);
  }

  &.login {
    position: fixed;
    max-width: 50%;
    width: 2580px;
    left: auto;
  }
}
