.avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: $primary-light-2;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  font-size: $h3-font-size;
  color: $white;
  text-align: center;
  position: relative;

  img {
    max-width: 100%;
  }

  span {
    @include vertical-align(50%);
    display: block;
  }

  &-xs {
    width: 32px;
    height: 32px;
    font-size: $h5-font-size;
  }
  &-sm {
    width: 40px;
    height: 40px;
    font-size: $h4-font-size;
  }
  &-lg {
    width: 80px;
    height: 80px;
    font-size: $h2-font-size;
  }
  &-xl {
    width: 112px;
    height: 112px;
    font-size: $h1-font-size;
  }
}
