html,
body {
  font-size: 14px;
  // background: $bg-color;
  line-height: 1.4;
}

h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400;
  line-height: 1.4;
}

.bg {
  &-pl {
    background: $bg-color;
  }
}

.scrollbar {
  --scrollbarBG: #cfd8dc;
  --thumbBG: $green;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);

  overflow: auto !important;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cdcdd3;
    // background-image: -webkit-gradient(
    //   linear,
    //   0 0,
    //   0 100%,
    //   color-stop(0.5, rgba(255, 255, 255, 0.2)),
    //   color-stop(0.5, transparent),
    //   to(transparent)
    // );
  }
}

.pointer {
  cursor: pointer;
}

.bg-primary-dark {
  background: $primary-dark-1;
}
.text-primary-light {
  color: $primary-light-2;
}

.small-xs {
  font-size: $font-size-xs;
}

.overflow-visible {
  overflow: visible !important;
}

.share {
  .block {
    padding: 8px 16px;
    border-radius: 4px;
    background: $white;
    border: 1px solid $border-color;
  }
  .btn {
    &-fb {
      background: #395398;
      border-radius: 4px;
      padding: 8px 16px;
      color: $white;
      cursor: pointer;

      svg {
        margin-right: 5px;
        margin-top: -4px;
      }

      &:hover {
        background: darken(#395398, 20%);
        text-decoration: none;
      }
    }
    &-tw {
      background: #55acee;
      border-radius: 4px;
      padding: 8px 16px;
      color: $white;
      cursor: pointer;

      svg {
        margin-right: 5px;
        margin-top: -4px;
      }

      &:hover {
        background: darken(#55acee, 20%);
        text-decoration: none;
      }
    }
    &-li {
      background: #0a66c2;
      border-radius: 4px;
      padding: 8px 16px;
      color: $white;
      cursor: pointer;

      svg {
        margin-right: 5px;
        margin-top: -4px;
      }

      &:hover {
        background: darken(#0a66c2, 20%);
        text-decoration: none;
      }
    }
  }

  &--item {
    display: inline-block;
    vertical-align: middle;
    margin: 15px 15px 15px 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

.title-border {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 1px solid $gray-300;
    bottom: 48%;
    z-index: 0;
  }
  span {
    padding-right: 5px;
    background: white;
    position: relative;
    z-index: 1;
  }
}
