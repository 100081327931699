.btn {
  padding: 6px 12px;
  border-radius: 4px;
  text-decoration: none;
  // text-transform: capitalize;
  min-width: 100px;

  &:focus {
    box-shadow: none !important;
    text-decoration: none;
  }

  [class^='ft-'] {
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
  }

  // size
  &-sm {
    padding: 6px 12px;
  }

  &-lg {
    padding: 8px 16px;
    // text-transform: uppercase;
  }

  // color
  // primary
  &-primary {
    &:hover,
    &:focus {
      background-color: $primary-dark-1;
      border-color: $primary-dark-1;
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active,
    &.active {
      background-color: $primary;
      border-color: $primary;
      box-shadow: none;
    }

    &-link {
      min-width: auto;
      color: $primary;

      &:hover,
      &:active,
      &.active,
      &:focus {
        color: $primary-dark-1;
      }
    }
  }

  // secondary
  &-secondary {
    &:hover,
    &:focus {
      background-color: $secondary-dark-1;
      border-color: $secondary-dark-1;
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active,
    &.active {
      background-color: $secondary;
      border-color: $secondary;
      box-shadow: none;
    }

    &-link {
      min-width: auto;
      color: $secondary;

      &:hover,
      &:active,
      &.active,
      &:focus {
        color: $secondary-dark-1;
      }
    }
  }

  // success
  &-success {
    color: $white;

    &:hover,
    &:focus {
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active,
    &.active {
      background-color: $success;
      border-color: $success;
      box-shadow: none;
    }

    &-link {
      min-width: auto;
      color: $success;

      &:hover,
      &:active,
      &.active,
      &:focus {
        color: $success-light;
      }
    }
  }

  // danger
  &-danger {
    &:hover,
    &:focus {
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active,
    &.active {
      background-color: $danger;
      border-color: $danger;
      box-shadow: none;
    }

    &-link {
      min-width: auto;
      color: $danger;

      &:hover,
      &:active,
      &.active,
      &:focus {
        color: $danger-light;
      }
    }
  }

  // warning
  &-warning {
    color: $white;

    &:hover,
    &:focus {
      box-shadow: none;
      color: $white;
    }

    &:not(:disabled):not(.disabled):active,
    &.active {
      background-color: $warning;
      border-color: $warning;
      box-shadow: none;
    }

    &-link {
      min-width: auto;
      color: $warning;

      &:hover,
      &:active,
      &.active,
      &:focus {
        color: $warning-light;
      }
    }
  }

  // info
  &-info {
    color: $white;

    &:hover,
    &:focus {
      box-shadow: none;
      color: $white;
    }

    &:not(:disabled):not(.disabled):active,
    .active {
      background-color: $info;
      border-color: $info;
      box-shadow: none;
    }

    &-link {
      min-width: auto;
      color: $info;

      &:hover,
      &:active,
      &.active,
      &:focus {
        color: $info-light;
      }
    }
  }

  // light
  &-light {
    &:hover,
    &:focus {
      background-color: $gray-400;
      border-color: $gray-400;
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active,
    &.active {
      background-color: $light;
      border-color: $light;
      box-shadow: none;
    }

    &-link {
      min-width: auto;
      color: $gray-500;

      &:hover,
      &:active,
      &.active,
      &:focus {
        color: $gray-800;
      }
    }
  }

  // dark

  // link
  &-link {
    min-width: auto;

    &:hover {
      text-decoration: none;
    }
  }

  // disabled
  &-disabled,
  &:disabled,
  &.disabled {
    background-color: $gray-500;
    border-color: $gray-500;
    color: $white;
  }

  // stroke or outline
  &-outline {
    color: $gray-500;
    border-color: $gray-300;

    // primary
    &-primary {
      &:disabled,
      &.disabled {
        background-color: transparent;
        border-color: $gray-500;
        color: $gray-500;
      }
    }

    // secondary
    &-secondary {
      &:disabled,
      &.disabled {
        background-color: transparent;
        border-color: $gray-500;
        color: $gray-500;
      }
    }

    // success
    &-success {
      &:hover {
        color: $white;
      }

      &:disabled,
      &.disabled {
        background-color: transparent;
        border-color: $gray-500;
        color: $gray-500;
      }
    }

    // danger
    &-danger {
      &:disabled,
      &.disabled {
        background-color: transparent;
        border-color: $gray-500;
        color: $gray-500;
      }
    }

    // warning
    &-warning {
      &:hover {
        color: $white;
      }

      &:disabled,
      &.disabled {
        background-color: transparent;
        border-color: $gray-500;
        color: $gray-500;
      }
    }

    // info
    &-info {
      &:hover {
        color: $white;
      }

      &:disabled,
      &.disabled {
        background-color: transparent;
        border-color: $gray-500;
        color: $gray-500;
      }
    }

    // light
    &-light {
      color: $gray-500;
      border-color: $gray-500;

      &:disabled,
      &.disabled {
        background-color: transparent;
        border-color: $gray-500;
        color: $gray-500;
      }
    }

    // dark
    // link
  }

  &-white {
    color: $white;
    border-color: $white;

    &:hover,
    &:focus {
      background-color: $white;
      border-color: $white;
      color: $primary;
      box-shadow: none;
    }
  }

  // icon
  &-icon {
    min-width: auto;

    &-round {
      min-width: auto;
      padding: 2px 4px;
      background: $gray-400;
      border-radius: 50%;
      margin-top: -3px;
    }
  }
}

.btn-ft {
  display: inline-block;
  text-decoration: none;
  border: none;
  background-color: transparent;
  box-shadow: none !important;
  padding: 4px;

  //size
  &_sm {
    font-size: 16px;
  }

  &_lg {
    font-size: 24px;
  }

  //Color
  //Primary
  &_primary {
    color: $primary;

    &:hover,
    &:focus,
    &:active {
      color: darken($primary, 10%);
    }
  }

  &_secondary {
    color: $secondary;

    &:hover,
    &:focus,
    &:active {
      color: darken($secondary, 10%);
    }
  }

  &_success {
    color: $success;

    &:hover,
    &:focus,
    &:active {
      color: darken($success, 10%);
    }
  }

  &_danger {
    color: $danger;

    &:hover,
    &:focus,
    &:active {
      color: darken($danger, 10%);
    }
  }

  &_warning {
    color: $warning;

    &:hover,
    &:focus,
    &:active {
      color: darken($danger, 10%);
      color: $warning-light;
    }
  }
}
