.modal {
  &-header {
    border: 0;
    padding: 16px 16px;
  }

  &-body {
    overflow: auto;
    padding: 16px 16px;
    @extend .scrollbar;
  }

  &.left {
    &.fade {
      .modal-dialog {
        right: -320px;
        -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
        -o-transition: opacity 0.3s linear, left 0.3s ease-out;
        transition: opacity 0.3s linear, left 0.3s ease-out;

        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
      }
      &.show {
        .modal-dialog {
          left: 0;
        }
      }
    }
  }

  &.right {
    &.fade {
      .modal-dialog {
        right: -320px;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;

        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
      }
      &.show {
        .modal-dialog {
          right: 0;
        }
      }
    }
  }

  &.get__strates {
    text-align: center;
    .modal-content {
      overflow: hidden;
    }
    .modal-body {
      border-top: 10px solid $primary;
    }
  }
}
