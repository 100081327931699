@include only-for-desktop() {
  .app-slogan {
    font-size: 43px;
    font-weight: $font-weight-bold;
  }

  .login__wrapper {
    .left .content {
      top: 95%;
      transform: translateY(-95%);
    }

    .right {
      .content {
        padding: 15px 0 0;
      }
    }
  }

  .form {
    &__onboarding {
      // padding: 12px 0 0;
    }
  }
}
