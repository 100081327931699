.nav {
  border-bottom: none;
  &-tabs {
    .nav-item {
      margin-right: 8px;
    }
    .nav-link {
      color: $gray-700;
      border: none;
      font-size: $font-size-lg;
      line-height: 24px;
      padding: 4px;
      &.active {
        border-bottom: 2px solid $primary;
        color: $primary;
      }
      &:hover {
        color: $primary;
      }
    }
  }
}
