.wrapper {
  display: flex;
  height: 100vh;
  overflow: hidden;

  &-contain {
    max-height: 100%;
    @extend .scrollbar;
  }

  .email-support {
    position: absolute;
    right: 15px;
    top: 10px;
    z-index: 1;
  }
}

.main--wrapper {
  height: 100vh;
  @extend .scrollbar;
  overflow-x: hidden !important;

  .wrapper {
    height: auto;
    min-height: calc(100vh - 62px);

    .content {
      width: 100%;
    }
  }
}

.block {
  &-primary {
    color: $primary;
    background: $bg-color;
    padding: 8px 15px;
    font-size: $h4-font-size;
    display: inline-block;
    border-radius: 4px;
  }
}

.app-slogan {
  font-weight: $font-weight-bold;
}
