aside {
  width: 100%;
  padding: 10px 0 18px 16px;
  max-width: 254px;
  display: block;
  background: $primary-dark-1;

  .toggle {
    display: none;
    cursor: pointer;

    &--icon {
      display: none;
      cursor: pointer;
      color: $white;
    }
  }

  .mainmenu {
    overflow-x: hidden;
    background: $primary-dark-1;
    width: 100%;
    padding-right: 23px;

    height: calc(100% - 56px);

    @extend .scrollbar;

    .mb-bg {
      display: flex;
      flex-wrap: wrap;
      max-width: 254px;
      height: 100%;
      background: $primary-dark-1;
    }
  }

  .logo {
    padding: 16px 16px;
    display: block;
    width: 100%;
    position: relative;

    .logo--sm {
      display: none;
    }
  }

  .user {
    display: flex;
    align-items: center;
    color: $white;
    width: 100%;
    position: relative;
    max-width: 216px;

    .image--block {
      position: relative;
      width: 38px;
      height: 38px;
      border: 1px solid $primary-light-2;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 15px;
      background: $primary-light-1;
      text-align: center;
      color: $primary;

      .text {
        margin: 0;
        line-height: 36px;
        font-size: 20px;
        font-weight: $font-weight-bold;
        color: $white;
        text-transform: uppercase;
      }

      img {
        max-width: 100%;
      }
    }

    .ft-availability {
      position: absolute;
      top: 16px;
      left: 48px;
      color: $green;
    }

    &__info {
      width: calc(100% - 53px);
      overflow: hidden;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .nav {
    width: 100%;

    &--item {
      padding: 16px 20px;
      font-size: $h5-font-size;
      color: $primary-light-2;
      display: flex;
      align-items: center;
      border-radius: 8px;
      margin-bottom: 4px;
      width: 100%;
      cursor: pointer;

      .text {
        margin-left: 15px;
        width: calc(100% - 80px);
        @include text-truncate;
      }

      &:hover {
        background: $primary;
        color: $white;
        text-decoration: none;
      }

      &.active {
        background: $primary;
        color: $white;
      }

      &.disabled {
        opacity: 0.8;
        pointer-events: none;
      }
    }
  }

  &.toggle {
    max-width: 120px;

    .user {
      img {
        margin-right: 0;
      }

      &__info {
        width: 0;
      }
    }

    .logout {
      .text {
        margin-left: 0;
        width: 0;
      }
    }

    .nav {
      &--item {
        text-align: center;
        .text {
          margin-left: 0;
          width: 0;
        }
      }
    }
  }
}

aside.light {
  width: 100%;
  display: block;
  padding: 0;
  background: $white;
  max-width: 249px;
  min-width: 10ox;
  @include transition(all, ease-in-out, 250ms);

  .logo {
    &--lg {
      display: block;
    }

    &--sm {
      display: none;
    }
  }

  .sidebar {
    &--block {
      position: relative;
      border-right: 1px solid $gray-300;

      .toggle-md {
        position: absolute;
        top: 15px;
        right: -10px;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        background-color: $bg-color;
        border: 1px solid $primary-light-2;
        cursor: pointer;
        padding: 4px 4px;
        z-index: 1;
        color: $white;

        @include transition(all, ease-in-out, 300ms);

        span {
          color: $primary;
          width: 8px;
          height: 8px;
          display: block;
          margin: 6px 2px;
          @extend .ft-rotate-270;
        }
      }
    }
    &__content {
      height: calc(100vh - 63px);
      padding: 16px 16px;
      @extend .scrollbar;
    }
  }

  .nav {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &__dropdown {
      border-radius: 4px;
      overflow: hidden;
      max-height: 61px;

      @include transition(all, 300ms, ease-in-out);

      &.show {
        background-color: $bg-color;
        max-height: 240px;
      }
    }

    &--item {
      padding: 8px 10px 8px 8px;
      font-size: $font-size-base;
      color: $gray-900;
      display: flex;
      align-items: center;
      border-radius: 0px;
      border-left: 4px solid transparent;
      margin: 12px 0px;
      width: 100%;
      cursor: pointer;

      .text {
        margin-left: 10px;
        width: calc(100% - 31px);
        @include text-truncate;
        @include transition(all, ease-in-out, 300ms);
      }

      &:hover {
        background: $bg-color;
        color: $primary;
        text-decoration: none;
      }

      &.active {
        background: $bg-color;
        color: $primary;
        border-left-color: $primary;
      }

      &.disabled {
        opacity: 0.8;
        pointer-events: none;
      }
    }
  }

  &.sidebar__sm {
    max-width: 80px;

    .sidebar {
      &--block {
        .toggle-md {
          @extend .ft-rotate-180;
          span {
            display: block;
          }
        }
      }
    }

    .nav__dropdown {
      background: $white;
      .nav--item:first-child {
        display: none;
      }
    }

    .nav--item {
      .text {
        margin-left: 0px;
        max-width: 1px;
        overflow: hidden;
      }
    }
  }
}

.main-menu {
  padding: 16px 16px;
  border-radius: 4px;
  width: 644px;
  max-width: 100vw;

  .nav__item {
    display: inline-block;
    width: 50%;
    padding: 16px 16px;

    .title {
      margin: 4px 0;
      display: block;
      font-size: $h4-font-size;
      font-weight: $font-weight-bold;
    }

    &:hover {
      text-decoration: none;
      background: $bg-color;
    }

    &.active {
      background: $bg-color;
    }
  }
}
