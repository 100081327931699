.accordion {
  &.faq {
    .card {
      border-width: 1px 0px 1px 0px;
      background: transparent;
      border-radius: 0;
      cursor: pointer;

      &-header {
        background: transparent;
        font-weight: $font-weight-bold;
        padding: {
          left: 0;
          right: 0;
        }
      }

      &:first-child {
        border-width: 0px 0px 1px 0px;
      }
    }
  }
}
