.form {
  &__onboarding {
    display: block;
    flex-flow: column;
    height: 100vh;
    padding: 12px 0 0;
    margin-right: -15px;
    position: relative;

    @extend .fade-in;

    @extend .scrollbar;

    .email-support {
      position: absolute;
      right: 15px;
      top: 15px;
    }

    .content {
      margin: auto;
      max-width: 550px;
      display: block;

      .form-control {
        // height: 42px;
      }
    }

    &.vt-2 {
      .content {
        margin: auto;
        max-width: 700px;
        display: block;
      }

      .form--footer {
        padding: 12px 12px;
        background: rgba($white, 90%);
      }
    }
  }

  &--header {
    padding: 8px 12px;
  }

  &--body {
    padding: 8px 15px;
    min-height: calc(100% - 112px - 74px);

    @extend .scrollbar;

    .upload {
      &__resume {
        width: 100%;
        background: $bg-color-1;
        border-radius: 4px;
        text-align: center;
        position: relative;
        border: 1px dashed $gray-300;

        &.dragover {
          background: lighten($primary, 40%);
          outline: 6px dashed $primary;
          outline-offset: -30px;
        }

        input[type='file'] {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          opacity: 0;
          z-index: 0;
        }

        label {
          margin: 0;
          cursor: pointer;
          color: $primary;

          &:hover {
            text-decoration: underline;
          }
        }

        .content {
          z-index: 1;
          position: relative;
          display: block;
          cursor: default;
          min-height: 200px;

          .progress {
            height: 10px;
          }
        }

        &.success {
          input[type='file'] {
            // position: relative;
            // display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            opacity: 0;
            z-index: 0;
          }
          .content {
            z-index: 1;
            text-align: left;
            width: 90%;

            label.btn:hover {
              color: $white;
              text-decoration: none;
            }
          }

          @extend .fade-in;
        }
      }
    }

    .add-item {
      background: $white;
      border-radius: 4px;
      border: 1px solid $primary;
      padding: 16px 14px;
      overflow: hidden;
      margin-bottom: 10px;
    }

    .role-list {
      display: flex;
      flex-wrap: wrap;
      column-gap: 2px;
    }
  }

  &--footer {
    position: sticky;
    bottom: 0;
    padding: 12px 12px 20px;
    width: 100%;

    background: rgba($bg-color, 80%);
    z-index: 1;

    .content {
      display: flex;
      margin: 0;
      max-width: 620px;
    }
  }

  &__widget1 {
    color: $white;
    height: 100vh;
    display: flex;
    flex-direction: column;

    &--item {
      align-items: center;
      padding: 12px 0 25px;
      position: relative;

      .num {
        width: 32px;
        height: 32px;
        color: $black;
        padding: 5px 12px;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid $white;
        background-color: $primary-dark-1;
        color: $white;
        z-index: 1;
        position: relative;
      }

      .text {
        margin: 0;
        @extend .h5;
      }

      .step-progress {
        width: 36px;
        position: absolute;
        top: 61px;
        left: -1px;
        z-index: 1;

        @include rotate(90deg);

        .progress {
          height: 2px;
          background: $primary;

          .progress-bar {
            background-color: $white !important;
          }
        }
      }

      &.active {
        .num {
          background: $white;
          color: $black;
        }
      }

      &.done {
        opacity: 1;
        .num {
          position: relative;
          border-color: $green;

          &:after {
            content: '\2713';
            position: absolute;
            width: 32px;
            height: 32px;
            color: $white;
            background: $green;
            left: 0;
            top: 0;
            padding: 5px 10px;
          }
        }

        &:after {
          background: $green;
        }

        .step-progress {
          .progress {
            background: $green;

            .progress-bar {
              background-color: $green !important;
            }
          }
        }
      }

      &:after {
        content: '';
        width: 2px;
        height: 100%;
        position: absolute;
        left: 16px;
        top: 16px;
        z-index: 0;
        background: $primary;
      }

      &:last-child {
        .step-progress {
          display: none;
        }
        &:after {
          display: none;
        }
      }
    }

    .form--procress {
      margin-top: 25px;
      max-height: calc(100% - 41px - 200px);
      @extend .scrollbar;
    }

    .power-by {
      position: absolute;
      bottom: 10px;
      left: 65px;
    }

    .carousel {
      border-bottom: 1px solid $gray-500;
      padding: 15px 0 30px;
      margin-bottom: 15px;

      &-indicators {
        left: auto;
        margin: 0 0 15px 0;

        [data-bs-target] {
          width: 8px;
          height: 4px;
          background: rgba(197, 182, 224, 0.5);
          border-radius: 8px;
          border: 0;

          @include transition(all, 300ms, ease-in-out);
        }

        .active {
          width: 24px;
        }
      }

      .carousel-control-prev,
      .carousel-control-next {
        display: none;
      }
    }

    .quotes {
      > div {
        max-width: 280px;
      }
    }

    .btn--logout {
      vertical-align: middle;
      margin-bottom: 12px;
      cursor: pointer;

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

.form {
  &__onboarding {
    display: block;
    flex-flow: column;
    height: 100vh;
    padding: 12px 0 0;
    margin-right: -15px;
    position: relative;

    @extend .fade-in;

    @extend .scrollbar;

    .email-support {
      position: absolute;
      right: 15px;
      top: 15px;
    }

    .content {
      margin: auto;
      max-width: 550px;
      display: block;

      .form-control {
        // height: 42px;
      }
    }
  }

  &--header {
    padding: 8px 12px;
  }

  &--body {
    padding: 8px 15px;
    min-height: calc(100% - 112px - 74px);

    @extend .scrollbar;

    .form-group {
      margin-bottom: 30px;
    }

    .upload {
      &__resume {
        width: 100%;
        background: $bg-color-1;
        border-radius: 4px;
        text-align: center;
        position: relative;

        &.dragover {
          background: lighten($primary, 40%);
          outline: 6px dashed $primary;
          outline-offset: -30px;
        }

        input[type='file'] {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          opacity: 0;
          z-index: 0;
        }

        label {
          margin: 0;
          cursor: pointer;
          color: $primary;

          &:hover {
            text-decoration: underline;
          }
        }

        .content {
          z-index: 1;
          position: relative;
          display: block;
          cursor: default;
          min-height: 250px;

          .progress {
            height: 10px;
          }
        }

        &.success {
          input[type='file'] {
            // position: relative;
            // display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            opacity: 0;
            z-index: 0;
          }
          .content {
            text-align: left;
            width: 90%;

            label.btn:hover {
              color: $white;
              text-decoration: none;
            }
          }

          @extend .fade-in;
        }
      }
    }

    .add-item {
      background: $white;
      border-radius: 4px;
      border: 1px solid $primary;
      padding: 16px 14px;
      overflow: hidden;
      margin-bottom: 15px;
    }

    .role-list {
      display: flex;
      flex-wrap: wrap;
      column-gap: 2px;
    }
  }

  &--footer {
    position: sticky;
    bottom: 0;
    padding: 12px 12px 20px;
    width: 100%;

    background: rgba($bg-color, 80%);
    z-index: 1;

    .content {
      display: flex;
      margin: 0;
      max-width: 620px;
    }
  }

  &__widget {
    padding: 12px 50px 25px;
    color: $white;

    &--item {
      align-items: center;
      padding: 12px 0;
      opacity: 0.3;

      .num {
        width: 32px;
        height: 32px;
        color: $black;
        padding: 5px 12px;
        position: relative;
        border-radius: 50%;
        background: $white;
        overflow: hidden;
      }

      .text {
        margin: 0;
        @extend .h5;
      }

      &.active {
        opacity: 1;
      }

      &.done {
        opacity: 1;
        .num {
          position: relative;

          &:after {
            content: '\2713';
            position: absolute;
            width: 32px;
            height: 32px;
            color: $white;
            background: $green;
            left: 0;
            top: 0;
            padding: 5px 10px;
          }
        }

        .text {
          opacity: 0.7;
        }
      }
    }

    .power-by {
      position: absolute;
      bottom: 10px;
      left: 65px;
    }
  }
}

.bg-primary-dark.col-md-5 {
  max-height: 100vh;

  @extend .scrollbar;
}
