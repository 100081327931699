.hiring {
  &__procress {
    display: flex;
    max-width: 570px;
    width: 100%;

    .step {
      flex: 1 auto;
      position: relative;
      font-size: $font-size-sm;
      color: $gray-600;
      min-width: 126px;

      .num {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 1px solid $primary;
        background: $white;
        text-align: center;
        padding: 5px 2px;
        line-height: 1;
        color: $primary;
        margin-bottom: 5px;
        position: relative;
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        height: 1px;
        background: $primary;
        left: 0;
        right: 0;
        top: 12px;
      }

      &:last-child {
        min-width: auto;

        &:after {
          display: none;
        }
      }

      &.active {
        color: $gray-900;
        .num {
          background: $primary;
          color: $white;
        }
      }

      &.done {
        color: $gray-900;
        .num {
          color: $green;
          background: $green;
          position: relative;

          &:after {
            font-family: 'fusetalent' !important;
            font-style: normal;
            font-weight: normal;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            content: '\e910';
            color: $white;
            position: absolute;
            left: 5px;
            top: 5px;
          }
        }
      }
    }

    &.block {
      border: 1px solid #dbdbdf;
      border-radius: 8px;
      background: $white;
      display: flex;
      max-width: 100%;
      width: 100%;

      .step {
        position: relative;
        font-size: $font-size-base;
        color: $gray-600;
        flex-grow: 1;

        .num {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          border: 1px solid $primary;
          background: $white;
          text-align: center;
          padding: 8px 2px;
          line-height: 1;
          color: $primary;
          margin-bottom: 5px;
          position: relative;
          z-index: 1;
        }

        .btn {
          width: 100%;
        }

        &:after {
          content: '';
          position: absolute;
          height: 1px;
          background: $primary;
          left: 0;
          right: 0;
          top: 12px;
        }

        &:last-child {
          min-width: auto;

          &:after {
            display: none;
          }
        }

        &.active {
          color: $gray-900;
          .num {
            background: $primary;
            color: $white;
          }
        }

        &.done {
          color: $gray-900;
          .num {
            color: $green;
            background: $green;
            border: $green;
            position: relative;

            &:after {
              font-family: 'fusetalent' !important;
              font-style: normal;
              font-weight: normal;
              font-feature-settings: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              content: '\e910';
              color: $white;
              position: absolute;
              left: 7px;
              top: 7px;
            }
          }

          &:after {
            background: $green;
          }
        }
      }
    }
  }
}
