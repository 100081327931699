.top--nav {
  padding: 8px 0;
  background: $primary-dark-1;
  display: flex;
  align-items: center;
  min-height: 62px;

  .nav {
    &--left {
      padding: 0 15px;
      .logo {
        &--sm {
          display: none;
        }
      }

      .logo-content {
        color: $white;
        margin-left: 12px;
        .logo {
          font-size: $h4-font-size;
          font-weight: 600;
          line-height: 1;
        }
      }

      .nav--breadcrumb {
        margin-left: 16px;
        padding-left: 16px;
        border-left: 1px solid #fff;
        color: $white;
      }
    }
    &--right {
      padding: 0 15px;
      margin-left: auto;

      .user {
        display: flex;
        align-items: center;
        color: $white;
        width: 100%;
        position: relative;
        max-width: 260px;
        padding: 4px 8px 4px 5px;
        border-radius: 40px;
        background: $primary;
        cursor: pointer;

        .image--block {
          position: relative;
          width: 38px;
          height: 38px;
          border: 1px solid $primary-light-2;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 8px;
          background: $primary-light-1;
          text-align: center;
          color: $primary;

          .text {
            margin: 0;
            line-height: 36px;
            font-size: 20px;
            font-weight: 500;
            color: $white;
            text-transform: uppercase;
            @include transition(all, ease-in-out, 300ms);
          }

          img {
            max-width: 100%;
          }
        }

        &__info {
          width: calc(100% - 53px);
          overflow: hidden;
          @include transition(all, ease-in-out, 300ms);
        }

        &:hover {
          text-decoration: none;
          background: $primary;
        }
      }

      .dropdown-menu {
        padding: 24px 20px;
        border-radius: 0px 0px 8px 8px;
        box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);
        border: 0;

        .user {
          border-radius: 0;
          background: $white;

          color: $gray-900;

          &:hover {
            text-decoration: none;
            background: $primary-light-2;
            color: $white;
          }
        }
      }
    }
  }
}

.nav--grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-gap: 30px;

  .nav__item {
    border-top: 4px solid $primary;
    border-radius: 4px;
    box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);
    padding: 16px 16px;
    max-width: 100%;
    color: $primary-dark-1;

    span {
      display: block;

      &.title {
        font-size: $h4-font-size;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }
}
