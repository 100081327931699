.profile {
  @extend .fade-in;

  .user--image {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;

    img {
      max-width: 100%;
    }

    .text {
      font-size: 3rem;
      color: $white;
      background: $primary;
      padding: 0.56em 0;
    }
  }

  .timeline {
    &--item {
      padding: 0 0 15px 30px;
      position: relative;

      &:before {
        content: '';
        width: 10px;
        height: 10px;
        background: $gray-300;
        border-radius: 50%;
        position: absolute;
        top: 6px;
        left: 10px;
      }

      &:after {
        content: '';
        width: 1px;
        height: 100%;
        background: $gray-300;
        border-radius: 50%;
        position: absolute;
        top: 6px;
        left: 14px;
      }

      &:last-child:after {
        display: none;
      }
    }
  }

  .badges {
    margin: 0 5px 10px 0;
    display: inline-block;
  }

  .sticky__nav--profile {
    max-width: 180px;
    position: sticky;
    top: 25px;

    .item {
      margin: 20px 0;
      font-weight: $h5-font-size;
      cursor: pointer;
      border-bottom: 1px solid transparent;

      @extend .h5;

      a {
        color: $gray-800;
      }

      &:hover,
      &.active {
        a {
          text-decoration: underline;
          color: $primary;
        }
      }
    }
  }
}

.additional--block {
  padding: 12px 15px;
  border: 1px solid $primary;
  border-radius: 4px;
  margin-bottom: 15px;
}
