.css-b62m3t-container {
  position: relative;
  // z-index: 9;
}

.css-14el2xx-placeholder {
  white-space: nowrap;
  overflow: hidden;
  color: $gray-300 !important;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  padding: 0 4px 0 0 !important;
}

.css-1s2u09g-control {
  min-height: 45px !important;
  border-color: $gray-300 !important;
  box-shadow: none !important;
  // min-height: auto !important;
  box-shadow: none !important;

  &:hover {
    border-color: $primary !important;
  }
}

.css-1pahdxg-control {
  min-height: 45px !important;
  border-color: $primary !important;
  box-shadow: none !important;
  // min-height: auto !important;
  box-shadow: none !important;

  &:hover {
    border-color: $primary !important;
  }
}

.css-14dclt2-Input {
  margin: 0 !important;
}

// .css-26l3qy-menu,
// .react-select__menu,
[class*='-MenuList'] {
  max-height: 285px !important;
  @extend .scrollbar;
}

[class*='-option'] {
  word-break: break-word;
  overflow: hidden;
  padding-right: 15px !important;
}

.css-9gakcf-option {
  background-color: #cde1ff !important;
  color: $gray-900 !important;
}

.css-26l3qy-menu,
.css-1w9j89e-menu {
  min-width: 140px;
}

.multi-select {
  .css-1s2u09g-control,
  .css-1pahdxg-control {
    height: auto;
    min-height: 45px !important;
  }
}
