.dropdown {
  &-toggle {
    &:focus {
      box-shadow: none !important;
    }
  }

  &-menu {
    padding: 0;
    border-radius: 2px;
    overflow: hidden;
    min-width: 80px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

    .dropdown-item {
      padding: 12px 12px;
      border-bottom: 1px solid $gray-200;
      cursor: pointer;
      min-width: 150px;

      &:hover,
      &.active {
        background-color: $bg-color;
        color: inherit;
      }

      &:last-child {
        border: 0;
      }
    }

    &.w-lg {
      width: 400px;
      padding: 8px 15px;
    }

    &.w-md {
      width: 300px;
      padding: 8px 15px;
    }

    &.w-sm {
      width: 164px;
      padding: 8px 15px;
    }
  }

  &.icon {
    .dropdown-toggle {
      &:after {
        display: none;
      }
    }

    .dropdown-menu {
      .dropdown-item {
        padding: 6px 8px;
        font-size: $font-size-sm;
      }
    }
  }
}
