.custom-radio {
  position: relative;
  padding: 0;
  border-radius: 4px;
  background: $white;
  border: 1px solid $gray-300;

  .custom-control-label {
    padding: 12px 25px 12px 35px;
    cursor: pointer;

    &:before {
      width: 15px;
      height: 15px;
      left: 8px;
      top: 14px;
    }
  }

  .custom-control-input {
    &:checked {
      & ~ .custom-control-label {
        &:after {
          // background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='4' fill='%236FB989' stroke='%236FB989' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          background: $primary;
          width: 9px;
          height: 9px;
          border-radius: 10px;
          left: 11px;
          top: 17px;
        }

        &:before {
          background: transparent;
          border-color: $primary;
        }
      }
    }

    &:disabled,
    &[disabled] {
      ~ .custom-control-label {
        &:before {
          border: 1px solid $gray-400;
        }
      }
    }

    &:focus {
      ~ .custom-control-label {
        &:before {
          // box-shadow: none;
          outline: 2px solid $white;
          // border-radius: 0;
        }
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }
}
