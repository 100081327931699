.form {
  &--widget {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
    padding: 12px 0;
    overflow: hidden;
  }

  &-group {
    margin-bottom: 16px;

    &.error .form-control {
      border-color: $red;
    }
  }
}

.widget {
  &__item {
    position: relative;
    cursor: default;

    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid $primary-light-2;
      background: $primary-light-1;
      bottom: -12px;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 4px;
      right: 98%;
      background: $primary-light-2;
      bottom: -9px;
      /* "to left" / "to right" - affects initial color */
      background: linear-gradient(to left, $primary-light-2 50%, $primary 50%) right;
      background-size: 200%;
      transition: 300ms ease-out;
      width: 200px;
      background-position: right;
      border-radius: 10px;
    }

    &:last-child {
      &:before,
      &:after {
        right: 0;
      }
    }

    &:first-child:after {
      display: none;
    }

    &.active {
      color: $primary;

      &:before,
      &:after {
        background: currentColor;
      }

      &:after {
        z-index: 1;
        background-position: left;
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.form-control {
  height: 45px;
}

.pw--str {
  margin: -12px 0 18px 0;
  font-size: $small-font-size;
}
